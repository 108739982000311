import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

export interface MemberCardProps {
  name: string;
  pictureUrl: string;
  description: string;
}

const useStyles = makeStyles((theme) => ({
  memberImage: {
    height: "25rem",
    objectFit: "contain",
  },
}));

const MemberCard: React.FunctionComponent<MemberCardProps> = (
  props: MemberCardProps
) => {
  const styles = useStyles();

  return (
    <Card raised>
      <CardMedia
        className={styles.memberImage}
        image={props.pictureUrl}
        title={props.name}
        component="img"
      />
      <CardContent>
        <Typography variant="body1">{props.name}</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MemberCard;
