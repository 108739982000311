import { Paper, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import background from "../../assets/background_2.jpg";
import learn from "../../assets/learn.jpg";
import help from "../../assets/help.jpg";
import party from "../../assets/party.jpg";
import IconCard from "./IconCard";
import StoryblokClient from "../../services/StoryblokService";
import { render } from "storyblok-rich-text-react-renderer-beta";

export interface HomeProps {}

const useStyles = makeStyles((theme) => ({
  homePageBox: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    padding: "3rem",
  },
  card: {
    textAlign: "center",
    padding: "3rem 4rem",
    marginBottom: "5rem",
  },
  headerSpacing: {
    marginBottom: "2rem",
  },
  news: {
    wordWrap: "break-word"
  }
}));

const Home: React.FunctionComponent<HomeProps> = () => {
  const [pageInfo, setPageInfo] = useState<any>({});
  const styles = useStyles();

  useEffect(() => {
    async function getHomePage() {
      var response = await StoryblokClient.get("cdn/stories/home-page", {});
      setPageInfo(response.data.story.content);
    }

    getHomePage();
  }, []);

  return (
    <div>
      <Paper className={styles.card}>
        <Typography variant="h1" className={styles.headerSpacing}>
          Motivation
        </Typography>
        <Typography>{pageInfo.motivation}</Typography>
      </Paper>

      <Paper className={styles.card}>
        <Typography variant="h1" className={styles.headerSpacing}>
          News
        </Typography>
        <Typography className={styles.news}>{render(pageInfo.news, {})}</Typography>
      </Paper>

      <Grid container spacing={4}>
        <IconCard
          image={learn}
          header="Lernen"
          imageDescription="learn"
          text={pageInfo.learn}
        />
        <IconCard
          image={help}
          header="Helfen"
          imageDescription="help"
          text={pageInfo.help}
        />
        <IconCard
          image={party}
          header="Feiern"
          imageDescription="party"
          text={pageInfo.party}
        />
      </Grid>
    </div>
  );
};

export default Home;
