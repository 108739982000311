import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

export interface NavBarLinkProps {
  exact: boolean;
  label: string;
  to: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const useStyles = makeStyles((theme) => ({
  navLink: {
    textTransform: "uppercase",
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: "2rem",
    margin: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "1rem",
    },
  },
  activeNavLink: {
    color: theme.palette.primary.main,
  },
}));

const NavBarLink: React.FunctionComponent<NavBarLinkProps> = ({
  label,
  to,
  onClick,
}) => {
  const styles = useStyles();

  return (
    <NavLink
      to={to}
      className={({isActive}) => isActive ? `${styles.navLink} ${styles.activeNavLink}` : styles.navLink}
    >
      {label}
    </NavLink>
  );
};

export default NavBarLink;
