import { AppBar, Drawer, Hidden, IconButton, Toolbar } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/logo-min.jpg";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import NavBarLink from "./NavBarLink";

export interface NavBarProps {}

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "80px",
  },
  drawerPaper: {
    width: 240,
  },
  toolbar: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
}));

const NavBar: React.FunctionComponent<NavBarProps> = () => {
  const styles = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navLinks = (
    <React.Fragment>
      <NavBarLink to="/" exact onClick={handleDrawerToggle} label="Home" />
      <NavBarLink
        to="/projects"
        exact={false}
        onClick={handleDrawerToggle}
        label="Projekte"
      />
      <NavBarLink
        to="/members"
        exact={false}
        onClick={handleDrawerToggle}
        label="Wer sind wir"
      />
      <NavBarLink
        to="/imprint"
        exact={false}
        onClick={handleDrawerToggle}
        label="Impressum"
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppBar position="sticky" color="inherit">
        <Toolbar className={styles.toolbar}>
          <Link to="/">
            <img
              className={styles.logo}
              src={logo}
              alt="Rotaract club logo"
            ></img>
          </Link>

          <Hidden mdDown implementation="css">
            {navLinks}
          </Hidden>

          <Hidden lgUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Hidden lgUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          classes={{ paper: styles.drawerPaper }}
          ModalProps={{ keepMounted: true }}
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
        >
          {navLinks}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default NavBar;
