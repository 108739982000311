import React, { useEffect, useState } from "react";
import { Card, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StoryblokService from "../../services/StoryblokService";
import { render } from "storyblok-rich-text-react-renderer-beta";
import { useNavigate } from "react-router-dom";

export interface ProjectsProps {}

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    marginTop: "2rem",
  },
  projectCard: {
    marginTop: "2rem",
    padding: "3rem",
  },
  button: {
    marginTop: "1rem",
    float: "right",
  },
}));

const Projects: React.FunctionComponent<ProjectsProps> = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [projects, setProjects] = useState<any[]>([]);

  useEffect(() => {
    async function getProjects() {
      const result = await StoryblokService.get("cdn/stories", {
        starts_with: "projects/",
        sort_by: "first_published_at:desc"
      });

      console.log(result.data.stories);

      setProjects(result.data.stories);
    }

    getProjects();
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h1">Projekte</Typography>
      <Typography variant="body1" component="p" className={styles.topSpacing}>
        Das Ziel unseres Clubs ist es, Menschen zu helfen. Dazu werden wir in
        Zukunft Projekte starten, die hier zu finden sind.
      </Typography>

      {projects.map((p) => (
        <Card raised className={styles.projectCard} key={p.id}>
          <Typography variant="h2">{p.content.title}</Typography>

          <Typography
            variant="body1"
            component="div"
            className={styles.topSpacing}
          >
            {render(p.content.summary, {})}
          </Typography>

          <Button
            className={styles.button}
            color="primary"
            variant="contained"
            onClick={() =>
              navigate(`/projects/${p.slug}`, { state: {full_slug: p.fullSlug }})
            }
          >
            Mehr
          </Button>
        </Card>
      ))}
    </React.Fragment>
  );
};

export default Projects;
