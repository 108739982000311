import React, { useState, useEffect } from "react";
import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StoryblokService from "../../../services/StoryblokService";
import { render } from "storyblok-rich-text-react-renderer-beta";
import { useParams } from "react-router-dom";
import "./details.css";

export interface ProjectDetailsProps {}

const useStyles = makeStyles((theme) => ({
  projectCard: {
    marginTop: "2rem",
    padding: "3rem",
  },
  content: {
    fontSize: "1.1rem",
    marginTop: "2rem",
  },
}));

const ProjectDetails: React.FunctionComponent<ProjectDetailsProps> = () => {
  const [project, setProject] = useState<any>({});
  const styles = useStyles();
  const params = useParams<any>();

  useEffect(() => {
    async function loadProject() {
      const result = await StoryblokService.get(
        `cdn/stories/projects/${params.projectSlug}`,
        {}
      );

      setProject(result.data.story.content);
    }

    loadProject();
  }, [params]);

  return (
    <Card raised className={styles.projectCard}>
      <Typography variant="h1">{project.title}</Typography>
      <div className={styles.content}>{render(project.content, {})}</div>
    </Card>
  );
};

export default ProjectDetails;
