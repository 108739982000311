import { makeStyles } from "@material-ui/core";
import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

export interface IconCardProps {
  image: string;
  header: string;
  imageDescription: string;
  text: string;
}

const useStyles = makeStyles((theme) => ({
  mottoCard: {
    textAlign: "center",
    padding: "1rem",
    height: "100%",
  },
  iconHeader: {
    marginBottom: "1rem",
  },
  iconImage: {
    width: "60%",
    objectFit: "contain",
    borderRadius: "100%",
    marginBottom: "1rem",
    "&::after": {
      display: "block",
      paddingBottom: "100%",
    },
  },
}));

const IconCard: React.FunctionComponent<IconCardProps> = (
  props: IconCardProps
) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Paper className={styles.mottoCard}>
        <Typography variant="h2" className={styles.iconHeader}>
          {props.header}
        </Typography>
        <img
          src={props.image}
          alt={props.imageDescription}
          className={styles.iconImage}
        />
        <Typography variant="body1">{props.text}</Typography>
      </Paper>
    </Grid>
  );
};

export default IconCard;
