import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import React, { useState, useEffect } from "react";
import StoryblokClient from "../../services/StoryblokService";

export interface FooterProps {}

const useStyles = makeStyles((theme) => ({
  footer: {
    height: "5.1rem",
    backgroundColor: "white",
    boxShadow:
      "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)",
    padding: "0.5rem",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  iconLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    verticalAlign: "center",
    margin: "0 2rem",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Footer: React.FunctionComponent<FooterProps> = () => {
  const [imprintInfo, setImprintInfo] = useState<any>({});
  const styles = useStyles();

  useEffect(() => {
    async function getImprintInfo() {
      var response = await StoryblokClient.get("cdn/stories/imprint", {});

      setImprintInfo(response.data.story.content);
    }

    getImprintInfo();
  }, []);

  return (
    <footer className={styles.footer}>
      <Container component="div" className={styles.contactContainer}>
        <Typography variant="h5">Kontakt</Typography>
        <div className={styles.container}>
          <a
            href={imprintInfo.facebook}
            target="blank"
            className={styles.iconLink}
          >
            <FacebookIcon fontSize="large" />
          </a>
          <a
            href={imprintInfo.instagram}
            target="blank"
            className={styles.iconLink}
          >
            <InstagramIcon fontSize="large" />
          </a>
          <a href={`mailto: ${imprintInfo.email}`} className={styles.iconLink}>
            <EmailIcon fontSize="large" />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
