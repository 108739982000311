import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import background from "./assets/background_2.jpg";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Home/Home";
import { Routes, Route } from "react-router-dom";
import MemberOverview from "./components/Members/Overview/MemberOverview";
import Imprint from "./components/Imprint/Imprint";
import Projects from "./components/Projects/Projects";
import ProjectDetails from "./components/Projects/ProjectDetails/ProjectDetails";
import Footer from "./components/Footer/Footer";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

let theme = createTheme({
  palette: {
    primary: {
      main: "#E30069",
    },
    secondary: {
      main: "#808080",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    h1: {
      color: "#E30069",
      fontSize: "3rem",
      fontWeight: "normal",
    },
    h2: {
      color: "#E30069",
      fontSize: "2.5rem",
      fontWeight: "normal",
    },
    body1: {
      fontSize: "1.5rem",
    },
  },
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(() => ({
  content: {
    marginTop: "1rem",
    width: "100%",
    flexGrow: 1,
    marginBottom: "1rem"
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover"
  },
}));

function App() {
  const styles = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={styles.pageContainer}>
        <NavBar />
        <Container className={styles.content} component="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/members" element={<MemberOverview />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/projects/:projectSlug" element={<ProjectDetails />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
