import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import StoryblokClient from "../../services/StoryblokService";
import { Typography } from "@material-ui/core";

export interface ImprintProps {}

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "1rem",
  },
}));

const Imprint: React.FunctionComponent<ImprintProps> = () => {
  const [imprintInfo, setImprintInfo] = useState<any>({});
  const styles = useStyles();

  useEffect(() => {
    async function getImprintInfo() {
      var response = await StoryblokClient.get("cdn/stories/imprint", {});

      setImprintInfo(response.data.story.content);
    }

    getImprintInfo();
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h1">Club Information</Typography>
      <Typography variant="body1">
        <b>Distrikt:</b> {imprintInfo.district}
      </Typography>
      <Typography variant="body1">
        <b>Club-Id:</b> {imprintInfo.club_id}
      </Typography>
      <Typography variant="body1">
        <b>ZVR-Zahl:</b> {imprintInfo.zvr}
      </Typography>
      <Typography variant="body1">
        <b>IBAN:</b> {imprintInfo.iban}
      </Typography>

      <Typography variant="h1" className={styles.section}>
        Kontakt
      </Typography>
      <Typography variant="body1">
        <b>Email: </b>
        <a href={`mailto: ${imprintInfo.email}`}>{imprintInfo.email}</a>
      </Typography>
      <Typography variant="body1">
        <b>Facebook: </b>
        <a href={imprintInfo.facebook}>Link</a>
      </Typography>
      <Typography variant="body1">
        <b>Instagram: </b>
        <a href={imprintInfo.instagram}>Link</a>
      </Typography>
    </React.Fragment>
  );
};

export default Imprint;
