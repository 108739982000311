import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StoryblokService from "../../../services/StoryblokService";
import MemberCard from "./MemberCard";

export interface MemberOverviewProps {}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: "2rem",
  },
}));

const MemberOverview: React.FunctionComponent<MemberOverviewProps> = () => {
  const [members, setMembers] = useState<any[]>([]);
  const styles = useStyles();

  useEffect(() => {
    async function getMembers() {
      const result = await StoryblokService.get("cdn/stories", {
        starts_with: "members/",
      });

      setMembers(result.data.stories);
    }

    getMembers();
  }, []);

  const createMemberCard = (member: any) => {
    return (
      <Grid key={member.slug} item xs={12} md={4}>
        <MemberCard
          key={member.id}
          name={member.name}
          pictureUrl={member.content.picture.filename}
          description={member.content.description}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h1" className={styles.heading}>
        Mitglieder
      </Typography>
      <Grid container spacing={4}>
        {members.map((m) => createMemberCard(m))}
      </Grid>
    </React.Fragment>
  );
};

export default MemberOverview;
