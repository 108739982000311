import StoryBlokClient from "storyblok-js-client";

class StoryBlokService {
    devMode: boolean;
    token: string;
    client: StoryBlokClient;
    query: Record<string, any>;

    constructor() {
        this.devMode = process.env.REACT_APP_STORYBLOK_DEV_MODE === "true" ?? false;
        this.token = "EEQdzDjN6WO21TmIyNeZkwtt";
        this.client = new StoryBlokClient({
            accessToken: this.token,
            cache: {
                clear: "auto",
                type: "memory"
            }
        });
        
        this.query = {}
    }

    getCacheVersion() {
        return this.client.cacheVersion;
    }

    get(slug: string, params: any) {
        params = params || {};

        if(this.getQuery("_storyblok") || this.devMode || (typeof window !== "undefined" && window.storyblok)) {
            params.version = "draft"
        }

        if(typeof window !== "undefined" && typeof window.StoryblokCacheVersion !== "undefined") {
            params.cv = window.StoryblokCacheVersion;
        }

        return this.client.get(slug, params);
    }

    setQuery(query: any) {
        this.query = query;
    }

    getQuery(param: any){
        return this.query[param];
    }
}

const storyblokInstance = new StoryBlokService();

export default storyblokInstance;